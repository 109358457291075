import React, { FC, useState ,FormEventHandler} from 'react';
import './Login.scss';import { useGridRowSelectionPreProcessors } from '@mui/x-data-grid/internals';
;
interface LoginProps { }

const Login: FC<LoginProps> = () => {
    
    const [errors, setErrors] = useState<String[]>([]);
    const handleChange: FormEventHandler<HTMLFormElement> = (e) => {
        e.preventDefault()
        var url = "/login" + window.location.search;
        
        fetch(url, 
            { 
                method: 'POST',
                body: new FormData(e.currentTarget)
            })
            .then(response => {
                // HTTP 301 response
                // HOW CAN I FOLLOW THE HTTP REDIRECT RESPONSE?
                if (response.ok && response.redirected) {
                    window.location.href = response.url;
                }
                else{
                    response.text().then(error=>{
                        setErrors([error])
                    });
                }
            }) 
            .catch((error) => {
                setErrors([error]);
            });
    };
    return (
        <div className="column is-4 is-offset-4">
            <h3 className="title">Login</h3>
            <div className="box">
                {
                    errors.map(e=><div className="notification is-danger">{ e }</div>)
                }
                <form method="POST" onSubmit={handleChange} >
                    <div className="field">
                        <div className="control">
                            <input className="input is-large" type="text" name="name" placeholder="Username" autoFocus={true} />
                        </div>
                    </div>

                    <div className="field">
                        <div className="control">
                            <input className="input is-large" type="password" name="password" placeholder="Password" />
                        </div>
                    </div>
                    <div className="field">
                        <label className="checkbox">
                            <input type="checkbox" name="remember" />
                            Remember me
                        </label>
                    </div>
                    <button className="button is-block is-info is-large is-fullwidth">Login</button>
                </form>
            </div>
        </div>
    );
}

export default Login;
