import React, { FC, useEffect, useState } from 'react';
import './BookingCalendar.scss';
import { Calendar, momentLocalizer,DateLocalizer,Culture,Event,DateRange,View,NavigateAction} from 'react-big-calendar'
import moment from 'moment';

const weekInSeconds = 7 * 24 * 60 * 60
const localizer = momentLocalizer(moment)
const timeGutterFormat = (d: Date, c?: Culture, l?: DateLocalizer)=> localizer.format(d, 'HH:mm', c);
const eventTimeRangeStartFormat = (d: DateRange, c?: Culture, l?: DateLocalizer) => localizer.format(d.start, 'HH:mm', c);
const eventTimeRangeEndFormat = (d: DateRange, c?: Culture, l?: DateLocalizer) => localizer.format(d.end, 'HH:mm', c);
const eventTimeRangeFormat = (d: DateRange, c?: Culture, l?: DateLocalizer) => eventTimeRangeStartFormat(d, c , l) + " - " + eventTimeRangeEndFormat(d, c , l);
interface BookingCalendarProps {
  bookings: never[];
}
const getMonday = (d:Date) => {
  const dt = new Date(d.getFullYear(),d.getMonth(),d.getDate());
  const day = dt.getDay()
  const diff = dt.getDate() - day + (day === 0 ? -6 : 1);
  return new Date(dt.setDate(diff));
}
const BookingCalendar: FC<BookingCalendarProps> = ({bookings}: BookingCalendarProps) => {
  const [events, setEvents ] = useState<Event[]>();
  const [today, setToday ] = useState(new Date());
  const onNavigate = (newDate: Date, view: View, action: NavigateAction) => {
    if(action !== 'DATE')
      setToday(newDate)
  }
  useEffect(() => {
    let monday = getMonday(today).getTime() / 1000
    setEvents(
      bookings
      .filter(booking=>
        booking['recurrence'] || booking['timestamp'] > monday)
      .map(booking =>{ 
        let timestamp:number = booking['timestamp']
        if(booking['recurrence'] && timestamp < monday)
        {
          console.log("adding to timestamp")
          timestamp +=  (Math.floor((monday - timestamp)/weekInSeconds) + 1) * weekInSeconds;
        }
        return {
          title: booking['user'],
          start: new Date(timestamp * 1000),
          end: new Date((timestamp + 60 * 60) * 1000),
      }}))
  },[today,bookings,setEvents]);
  return (<div className="BookingCalendar" >
            <h1>Planned bookings</h1>
            <Calendar
                  events={events}
                  formats={{
                      timeGutterFormat,
                      eventTimeRangeStartFormat,
                      eventTimeRangeEndFormat,
                      eventTimeRangeFormat
                    }}
                  onNavigate={onNavigate}
                  views={['week']}
                  defaultView='week'
                  timeslots={1}
                  localizer={localizer}
                  step={60}
                  defaultDate={new Date()}
                  popup={false}
                  eventPropGetter={(event: Event, start: Date, end: Date, isSelected: boolean) => {return {className: event.resource + isSelected ? " selected" : ""}}}
                  min={new Date( today.getFullYear(),  today.getMonth(), today.getDate(), 8 )}
                  max={new Date( today.getFullYear(),  today.getMonth(), today.getDate(), 20 )}
              />
          </div>);
}

export default BookingCalendar;
