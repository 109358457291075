import { Outlet, Link, redirect } from "react-router-dom";
import './Menu.scss';
import './bulma.min.css'

const Menu = () => {
  return (
    <section className="hero is-primary">
      <div className="hero-head">
        <nav className="navbar">
            <div className="container">
                <div id="navbarMenuHeroA" className="navbar-menu">
                    <div className="navbar-end">
                        <Link to="/" className="navbar-item"> Home </Link>
                        {
                          document.cookie.indexOf('logged_in=true') === -1 ? 
                            <Link to="/login" className="navbar-item"> Login </Link>  :
                            <Link to="/logout" onClick={()=>window.location.href = '/logout'}  className="navbar-item"> Logout </Link>
                        }
                        {/* <Link to="/signup" className="navbar-item"> SignUp </Link> */}
                    </div>
                </div>
            </div>
        </nav>
      </div>
      
      <div className="hero-body">
        <Outlet />
      </div>
    </section>
  )
};

export default Menu;