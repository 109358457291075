import React, { FC, useEffect, useState } from 'react';
import './ServerInfo.scss';
import moment from 'moment';

interface ServerInfoProps {}

const ServerInfo: FC<ServerInfoProps> = () => {
  const [time, setTime] = useState(0)
  useEffect(() => {
    fetch('/serverinfo',
    {     
      method: 'GET',
      headers: {
        "Accept": "application/json",
      }
    }).then(response =>{      
          if (response.ok) { 
            return response.json();
          }
          return Promise.reject(response);
      })
      .then(data => {
        setTime(data['servertime'])
      })
      .catch(error=>{console.log(error)})

    const interval = setInterval(() => {
      setTime(time => time + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  
  return ( <div className="ServerInfo">
              <h1>Server Date and Time</h1>
              <p>
                Servertime: { time > 0 ? moment(new Date(time*1000)).format("ddd DD.MM.YYYY HH:mm:ss") :""}
              </p>
          </div>)
};

export default ServerInfo;
