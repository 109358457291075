import React, { useEffect, useState } from 'react';
import './App.css';
import NothingToSee from './../../components/NothingToSee/NothingToSee';
import BookingList from './../../components/BookingList/BookingList';
import ServerInfo from './../../components/ServerInfo/ServerInfo';
import BookingAdd from './../../components/BookingAdd/BookingAdd';
import BookingCalendar from './../../components/BookingCalendar/BookingCalendar';

import * as momentTZ from 'moment-timezone';
momentTZ.tz.setDefault("Europe/Vienna");
momentTZ.locale("en-gb",{
  week: {
		dow: 1 //Monday is the first day of the week.
	}
});

function App() {
  const [bookings, setBookings] = useState([]);
  const [width, setWidth] = useState(window.innerWidth);
  const fetchBookings = () => {
    fetch('/booking',
    {     
      method: 'GET',
      headers: {
        "Accept": "application/json",
      }
    }).then(response =>{      
          if (response.ok) { 
            return response.json();
          }
          return Promise.reject(response);
      })
      .then(data => setBookings(data))
      .catch(error=>{console.log(error)})
  }
  
  useEffect(() => {
    fetchBookings();  
  }, []);
  
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);
  return (
    <div className='app'>
      <table className='topleft width100'>
        <tr>
          <td width={450}>
            <ServerInfo/>
            <BookingAdd rerender={fetchBookings}/>
          </td>
          {
            width > (400 + 600) &&
            <td height={600} >
              <BookingCalendar bookings={bookings} />
            </td>
          } 
        </tr>
      </table>
      <BookingList bookings={bookings} rerender={fetchBookings}/>
    </div>
  )
}

export default App;
