import React, { FC, useEffect, useState } from 'react';
import './BookingAdd.scss';

interface BookingAddProps {
  rerender?:()=>void
}

const BookingAdd: FC<BookingAddProps> = ({rerender}: BookingAddProps) => {
  const [date, setDate] = useState(new Date(Date.now() + 3 * 24 * 60 * 60 * 1000).toISOString().split('T')[0]);
  const [time, setTime] = useState("18:00")
  const [courtid, setCourtid] = useState(3934);
  const [password, setPassword] = useState("");
  const [passwordOverride, setPasswordOverride] = useState(false);
  const [description, setDescription] = useState("");
  const [user, setUser] = useState("");
  const [userExists, setUserExists] = useState(false);
  const [recurrence, setRecurrence] = useState(false);
  const [users, setUsers] = useState([]);
  const [courts, setCourts] = useState<Record<any, any[]>>({});

  function checkUserExists(username:string) {
    setUserExists(users.some(function(el) {
      return el["user"] === username;
    })); 
  }

  const fetchUsers = () => {
    fetch('/user',
    {     
      method: 'GET',
      headers: {
        "Accept": "application/json",
      }
    }).then(response =>{      
          if (response.ok) { 
            return response.json();
          }
          return Promise.reject(response);
      })
      .then(data => setUsers(data))
      .catch(error=>{console.log(error)})
  }
  const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) =>
    arr.reduce((groups, item) => {
      (groups[key(item)] ||= []).push(item);
      return groups;
    }, {} as Record<K, T[]>);
  const fetchCourts = () => {
    fetch('/court',
    {     
      method: 'GET',
      headers: {
        "Accept": "application/json",
      }
    }).then(response =>{      
          if (response.ok) { 
            return response.json();
          }
          return Promise.reject(response);
      })
      .then(data => {
        var list:any[] = data
        const grouped = groupBy(list, i => i["sportTypeKey"]);
        setCourts(grouped)
      })
      .catch(error=>{console.log(error)})
  }

  useEffect(() => {
    fetchUsers();
    fetchCourts();    
  }, []);

  const addBooking = () =>{
    const date_parts = date.split("-");
    const time_parts = time.split(":")
    fetch('/booking',
    {     
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        timestamp: new Date(
            parseInt(date_parts[0]),
            parseInt(date_parts[1]) - 1, // 
            parseInt(date_parts[2]),
            parseInt(time_parts[0]),
            parseInt(time_parts[1])).getTime() / 1000,
        courtid: courtid,
        user: user,
        password: userExists && !passwordOverride ? undefined : password,
        description: description,
        recurrence: recurrence
      })
    })
    .then(response=>{if(response.ok && rerender !== undefined) {rerender(); fetchUsers();}})
    .catch(error=>{console.log(error)})
  }
  return (
    <div className="BookingAdd" >
        <h1>Add planned booking</h1>
        <table><tbody>
          <tr>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <td><label htmlFor="res_date">Reservation date:</label></td>
            <td>
              <input type="date" id="res_date" name="res_date" 
                value={date} 
                min={new Date().toISOString().split('T')[0]}
                onChange={e=>setDate(e.target.value)} />
            </td>
          </tr>
          <tr>
            <td><label htmlFor="res_time">Time:</label></td>
            <td>
              <input id="res_time" type="time" name="res_time" value={time} onChange={event => setTime(event.target.value)} />
              <label className="comment" htmlFor="res_recurrence">Weekly(Yes/No):
                <input type="checkbox" id="res_recurrence" name="res_recurrence" checked={recurrence} onChange={e => setRecurrence(e.target.checked)}></input>
              </label>
            </td>
          </tr>
          <tr>
            <td><label htmlFor="res_courtid">Court:</label></td>
            <td>
              <select name="res_courtid" id="res_courtid" value={courtid} onChange={event => setCourtid(parseInt(event.target.value))}>
                {
                  Object.entries(courts).map(([key, values]) => {
                    return <optgroup label={key}>
                            {
                              values.map(value =>{
                                return <option value={value["id"]} >{value["id"]} - {value["name"]}</option>
                              })
                            }
                           </optgroup>
                  })
                }
              </select>
            </td>
          </tr>
          <tr>
            <td><label htmlFor="res_userid">User:</label></td>
            <td>
              <input type="text" name="res_userid" id="res_userid" list="userList" 
                    onChange={event => {
                      setUser(event.target.value)
                      checkUserExists(event.target.value)
                    }}
                    />
              <datalist id="userList">
                  {
                    users.map((user, index) => {
                      return <option value={user["user"]} />
                    })
                  }
              </datalist>
            </td>
          </tr>
          <tr>
            <td><label htmlFor="res_password">Password:</label></td>
            <td>
              <input type="password" disabled={userExists && !passwordOverride} id="res_password" name="res_password" value={password} onChange={e => setPassword(e.target.value)} required /><br/>
              {
                userExists ? 
                <div>
                  <label className="comment">User and Passwort exist already. We will use the saved one. Do you want to override</label><br/>
                  <label className="comment" htmlFor="res_password_override">Override(Yes/No):
                    <input type="checkbox" id="res_password_override" name="res_password_override" checked={passwordOverride} onChange={e => setPasswordOverride(e.target.checked)}></input>
                  </label>
                </div> : ""
              }
            </td>
          </tr>
          <tr>
            <td><label htmlFor="res_text">Description:</label></td>
            <td>
              <input type="text" id="res_text" value={description} onChange={e => setDescription(e.target.value)} placeholder="Any notes ([a-zA-Z0-9]*)" />
            </td>
          </tr>
        </tbody></table>
        <button onClick={addBooking} 
          disabled= {
              user === "" || 
              ((!userExists || passwordOverride) && password === "") ||
              date === "" ||
              time === "" 
            } 
          >Add Booking</button>
    </div>
  );
}

export default BookingAdd;
