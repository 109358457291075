import React, { FC } from 'react';
import './NothingToSee.scss';

interface NothingToSeeProps {}

const NothingToSee: FC<NothingToSeeProps> = () => (
  <div className="NothingToSee">
    Nothing to see here
  </div>
);

export default NothingToSee;
