import { FC, useEffect, useState } from 'react';
import './BookingList.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css'
import { DataGrid,GridColDef,GridRowSelectionModel } from '@mui/x-data-grid';
import moment from 'moment';
import { Tooltip } from '@mui/material';

interface BookingListProps {
  rerender?:()=>void
  bookings:never[]
}
const toDateString = (date:Date) => {
  return moment(date).format("ddd DD.MM.YYYY HH:mm");
}
const columns: GridColDef[] = [
  { 
    field: 'timestamp', 
    headerName: 'Time',
    flex: 1,
    valueFormatter: (params: any) => params?.value !== undefined ? toDateString(new Date(params.value * 1000)) : undefined
  },
  {
    field: 'recurrence', 
    headerName: 'Next',
    flex: 1, 
    valueGetter: (params: any) => params.row.recurrence && params.row?.timestamp !== undefined ?
        toDateString(new Date((params.row.timestamp + 7 * 24 * 60 * 60) * 1000)) : undefined
  },
  {
    field: 'courtid', headerName: 'Court', flex: 1,
    renderCell: (params: any) => (
      <a href={`https://app.courtculture.cc/radar(place:courts/${params?.value})`} target='_blank' rel="noreferrer" className="courtlink">{params?.value}</a>
    )
  },
  {
    field: 'operation', headerName: 'Operation', flex: 1, renderCell: (params: any) => (
      <Tooltip title={params.row?.exception}>
        <span>{params.value} {params.row?.exception !== undefined ? "😅" : ""}</span>
      </Tooltip> 
    ),
  },
  { field: 'user', headerName: 'User', flex: 1,
    renderCell: (params: any) => (
    <Tooltip title={params.value}>
      <span>{params.row?.displayName}</span>
    </Tooltip> 
    )
  },
  { field: 'description', headerName: 'Description', flex: 1  },
  { field: 'id', headerName: 'BookingId(CC)', flex: 1  },
];

const BookingList: FC<BookingListProps> = ({rerender,bookings}: BookingListProps) => {
  const [selection, setSelection ] = useState<GridRowSelectionModel>([]);
  const [width, setWidth] = useState(window.innerWidth);

  const deleteBookings = () =>{
    let body="{\"dels_keys\": [" + selection + "]}"
    console.log(body)
    fetch('/booking',
      {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: body
      })
    .then(response=>{if(response.ok && rerender !== undefined) { rerender();}})
    .catch(error=>{console.log(error)})
  }
  
  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  return (
    <div className="BookingList" >
      <h1>Planned bookings</h1>
      <button onClick={deleteBookings} disabled= {selection.length === 0}>Delete Booking ({selection.length})</button>
      <DataGrid
          rows={bookings}
          columns={columns}
          onRowSelectionModelChange={(newSelection: any)=>{
            setSelection(newSelection);
          }}
          getRowClassName={(row:any)=> {return row.exception !== undefined ? 'red':''}}
          getRowId={(row: any) =>  
            {
              if(row.id === undefined)
                return JSON.stringify({timestamp:row.timestamp,courtid:row.courtid})
              else
                return JSON.stringify({id:row.id})
            }}
          columnVisibilityModel={{
            timestamp: true,
            courtid: true,
            user: true,
            operation: width > 600,
            description: width > 700,
            id: width > 800,
            recurrence: width > 900,
          }}
          checkboxSelection
          disableRowSelectionOnClick
          hideFooter
        />
      
    </div>);
  } 



export default BookingList;
